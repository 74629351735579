import { type FC, memo, useState, useCallback, useEffect } from 'react';
import SwapHeader from './swap-header';
import styles from './index.module.scss';
import SwapSection from './swap-section';
import { useModel } from 'foca';
import { swapCoinModel } from '@models/swap-coin.model';
import SwapExchange from './swap-exchange';
import SwapSubmit from './swap-submit';
import { useSwapRoute } from '@hooks/use-swap-route';
import RouteInfo from '../swap-route';
import CoinAPrice from './coin-a-price';
import CoinBPrice from './coin-b-price';
import PythPrice from './pyth-price';
import { useCoinTypeMin } from '@hooks/use-coin-type-min';
import { useHash } from 'react-use';
import { findLocationSearch } from '@utils/location-search';

const Swap: FC = () => {
  const { coinTypeA, coinTypeB } = useModel(swapCoinModel);
  const { min } = useCoinTypeMin(coinTypeA);
  const [amount, setAmount] = useState<string>();
  const [amountSpecifiedIsInput, setAmountSpecifiedIsInput] = useState(false);
  const [deepbookActive, setDeepbookActive] = useState(false);
  const {
    fetchingBestRoute,
    bestRoute,
    amountIn,
    amountOut,
    insufficientLiquidity,
    isDeepbook,
    transactionBlock,
  } = useSwapRoute(amount, amountSpecifiedIsInput, deepbookActive);
  const hash = useHash();

  const handleTransacted = useCallback(() => {
    setAmount(undefined);
    // TO clear both input value
    setAmountSpecifiedIsInput((prev) => !prev);
  }, []);

  const handleSetInput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(true);
  }, []);

  const handleSetOutput = useCallback((value?: string) => {
    setAmount(value);
    setAmountSpecifiedIsInput(false);
  }, []);

  const handleExchange = useCallback(() => {
    if (deepbookActive) {
      setAmount(undefined);
    }
    swapCoinModel.exchange();
    setAmountSpecifiedIsInput((prev) => !prev);
  }, [deepbookActive]);

  useEffect(() => {
    if (coinTypeA && coinTypeB) {
      window.location.hash = `/trade?input=${coinTypeA}&output=${coinTypeB}`;
    }
  }, [coinTypeA, coinTypeB]);

  useEffect(() => {
    const _hash = hash[0].replace(/.*\?/, '');
    const input = findLocationSearch(_hash, 'input');
    const output = findLocationSearch(_hash, 'output');

    input && swapCoinModel.updateA(input);
    output && swapCoinModel.updateB(output);
  }, [hash[0]]);

  return (
    <div className={styles.wrapper}>
      <SwapHeader
        onDeepBookActive={(active: boolean) => {
          setDeepbookActive(active);
        }}
      />
      <SwapSection
        label="From"
        coinType={coinTypeA}
        onSelectCoin={swapCoinModel.updateA}
        disableCoins={coinTypeB}
        showMaxAmount={true}
        amount={amountIn}
        inputByUser={amountSpecifiedIsInput}
        onChangeValue={handleSetInput}
        deepbookActive={deepbookActive}
        onChangeAmount={setAmount}
      />
      <div className={styles.price_row}>
        <PythPrice coinType={coinTypeA} />
        {deepbookActive ? (
          <div className={styles.min}>Min amount: {min}</div>
        ) : (
          <CoinAPrice
            bestRoutes={bestRoute}
            amountIn={isDeepbook ? undefined : amountIn}
            className={styles.coin_price}
          />
        )}
      </div>

      <SwapExchange onExchange={handleExchange} />
      <SwapSection
        label="To"
        coinType={coinTypeB}
        onSelectCoin={swapCoinModel.updateB}
        disableCoins={coinTypeA}
        showMaxAmount={false}
        amount={amountOut}
        inputByUser={!amountSpecifiedIsInput}
        onChangeValue={handleSetOutput}
        deepbookActive={deepbookActive}
        inputDisabled={deepbookActive}
        onChangeAmount={setAmount}
      />
      <div className={styles.price_row}>
        <PythPrice coinType={coinTypeB} />
        <CoinBPrice
          bestRoutes={bestRoute}
          amountIn={amountIn}
          amountOut={isDeepbook ? undefined : amountOut}
          className={styles.coin_price}
        />
      </div>
      <SwapSubmit
        bestRoutes={bestRoute}
        fetchingBestRoute={fetchingBestRoute}
        amountSpecifiedIsInput={amountSpecifiedIsInput}
        insufficientLiquidity={insufficientLiquidity}
        amountIn={amountIn}
        amountOut={amountOut}
        isDeepbook={isDeepbook || deepbookActive}
        transactionBlock={transactionBlock}
        onTransacted={handleTransacted}
      />
      <RouteInfo
        amountIn={amountIn}
        amountOut={amountOut}
        bestRoutes={bestRoute}
        isDeepbook={isDeepbook}
        amountSpecifiedIsInput={amountSpecifiedIsInput}
      />
    </div>
  );
};

export default memo(Swap);
