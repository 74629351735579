import { type FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import styles from './top.module.scss';
import { swapCoinModel } from '@models/swap-coin.model';
import { useNavigate } from 'react-router-dom';

export const Top: FC = () => {
  const navigator = useNavigate();
  return (
    <div className={styles.top}>
      <Swiper
        modules={[Autoplay, Pagination]}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.blub}`}
            onClick={() => {
              navigator(
                '/pools/0x6a3be30a31f88d9055da7f26f53dd34c85bc5aab9028212361ccf67f5f00fd46/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.liq}`}
            onClick={() => {
              swapCoinModel.updateB(
                '0x9c86d1926a0a39e906f20674d6a35f337be8625ebcb6b799ee8ff011f328bee2::liq::LIQ',
              );
              swapCoinModel.updateA('0x2::sui::SUI');
              navigator(
                '/trade?input=0x2::sui::SUI&output=0x9c86d1926a0a39e906f20674d6a35f337be8625ebcb6b799ee8ff011f328bee2::liq::LIQ',
              );
            }}
          ></div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={`${styles.slide} ${styles.puff}`}
            onClick={() => {
              // swapCoinModel.updateB(
              //   '0xe9a376ada59b2ad927eaedc086dd0d7649b56433598ceda4f9d86ab95309e651::puff::PUFF',
              // );
              // swapCoinModel.updateA('0x2::sui::SUI');
              navigator(
                '/pools/0x74b335d489c325b1a49d81eb50f75bd9b2393282313c65933215616640f4430f/add-liquidity',
              );
            }}
          ></div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
